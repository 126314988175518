import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import LoginView from '@/views/authentication/Login.vue'
import RegisterView from '@/views/authentication/Register.vue'
import RecoverPassword from '@/views/authentication/RecoverPassword.vue'
import shopPage from '@/views/shop/profilePage.vue';

import HomeView from '../views/Home.vue'
import SearchView from '../views/search/mainSearch.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true }
  },
  {
    path: '/pesquisa/',
    name: 'pesquisa',
    component: SearchView,
    meta: { requiresAuth: true }
  },
  {
    path: '/establecimento/:id',
    name: 'establecimento',
    component: shopPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/conta',
    name: 'profile',
    component: () => import('../views/user/profile.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/conta/viaturas',
    name: 'profile.vehicles',
    component: () => import('../views/user/vehicles.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/conta/viaturas/:id',
    name: 'profile.vehicles.details',
    component: () => import('../views/user/seeVehicle.vue'),
  },
  {
    path: '/conta/viaturas/adicionar',
    name: 'profile.vehicles.add',
    component: () => import('../views/user/addNewVehicle.vue'),
    meta: { requiresAuth: true }
  }, 
  {
    path: '/logout',
    name: 'logout',
    meta: {
      requiresAuth: true
    },
    // Just create a html component saying "Logging out..."
    component: {
      template: '<div>Logging out...</div>'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/recover-password',
    name: 'recover-password',
    component: RecoverPassword
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // Check if to is logout
  if (to.name === 'logout') {
    // Remove jwt token from local storage and redirect to login
    localStorage.removeItem('token')
    window.location.href = '/login'
    next()
    return
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const token = localStorage.getItem('token') || ""
    if (token === "") {
      next('/login')
      return
    }

    const decodedToken = JSON.parse(atob(token.split('.')[1]))
    const currentTime = Date.now() / 1000
    if (token && decodedToken.exp > currentTime) {
      next()
      return
    } else {
      next('/login')
      return
    }
  }

  if (to.name === 'login' || to.name === 'register') {
    const token = localStorage.getItem('token') || ""
    if (token !== "") {
      next('/')
      return
    }
  }
  next()
})

// Check token status every minute
setInterval(() => {
  const token = localStorage.getItem('token') || ""
  if (token !== "") {
    const decodedToken = JSON.parse(atob(token.split('.')[1]))
    const currentTime = Date.now() / 1000
    if (decodedToken.exp < currentTime) {
      localStorage.removeItem('token')
      window.location.href = '/login'
    }
  }
}, 1000);

export default router
