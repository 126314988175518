<template>
    <div class="jumbotron jumbotron-fluid">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xxl-8 col-xl-8 col-12 offset-xxl-2 offset-xl-2 pb-5">
                    <h1 class="d-none d-xl-block">Está a um passo de explorer o<br>potencial DriverWise!</h1>
                    <h2 class="d-block d-xl-none">Está a um passo de explorer o<br>potencial DriverWise!</h2>
                    <h3>Faça hoje o seu registo!</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="container login mt-5 mb-5 pb-5 pt-5">
        <div class="row">
            <div class="col-12 text-center">
                <h2 style="font-size: 24px; font-weight: bolder;"><b>Iniciar Sessao</b></h2>
                <h5>Introduza o seu e-mail e a senha de acesso.</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-xxl-4 col-xl-4 col-lg-4 col-md-6 offset-xxl-4 offset-xl-4 offset-md-2">
                <form style="text-align: left;" method="post" :on-submit="login">
                    <div class="form-group">
                        <emailInput @onChange="onEmailInputUpdated" @keyup.enter="login"></emailInput>
                    </div>
                    <div class="form-group mt-2">
                        <PasswordInput @onChange="onPasswordInputUpdated" @keyup.enter="login"></PasswordInput>
                    </div>
                    <div class="form-group">
                        <PrimaryButton v-bind:extraClasses="'mt-1 mb-1'" v-bind:style="'width: 100%;'" v-bind:text="'Iniciar sessao'" v-bind:click="login" />
                    </div>
                </form>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-xxl-4 col-xl-4 col-md-6 col-lg-6 offset-xxl-4 offset-xl-4 offset-md-6 offset-lg-6">
                <div class="row">
                    <div class="col-5"><hr></div>
                    <div class="col-2"><p class="text-center">ou</p></div>
                    <div class="col-5"><hr></div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <GoogleLoginButton v-bind:click="login"></GoogleLoginButton>
                    </div>
                    <div class="col-12 mt-1">
                        <FacebookLoginButton v-bind:click="login"></FacebookLoginButton>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 text-center">
                        Não têm uma conta? <br>
                        <router-link to="/register">Registe-se agora!</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import PrimaryButton from '@/components/buttons/primaryButton.vue';
import FacebookLoginButton from '@/components/buttons/social/facebookLogin.vue';
import GoogleLoginButton from '@/components/buttons/social/googleLogin.vue';
import emailInput from '@/components/form/inputs/emailInput.vue';
import PasswordInput from '@/components/form/inputs/passwordInput.vue';
import { AuthService } from '@/services/authService';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'loginView',
  components: {
    PrimaryButton,
    GoogleLoginButton,
    FacebookLoginButton,
    emailInput,
    PasswordInput
  },
  data: function() {
    return {
        email: '',
        password: '',
        loggingIn: false
    }
  },
  mounted() {
    document.title = 'DriverWise - Iniciar Sessão';
  },
  methods: {
    onPasswordInputUpdated(password: string) {
      this.password = password;
    },
    onEmailInputUpdated(email: string) {
      this.email = email;
    },
    login() {
        if (this.loggingIn) {
            return;
        }
        this.loggingIn = true;
        // Check if email is a valid email by checking if it contains an @ symbol and a dot
        if (this.email.includes('@') && this.email.includes('.')) {
            // Check if password is not empty
            if (this.password !== '') {
                this.$swal({
                    icon: 'success',
                    title: 'Sessão Iniciada com Sucesso!',
                    showConfirmButton: false,
                    onOpen: () => {
                        this.$swal.showLoading();
                    }
                });

                AuthService.login(this.email, this.password)
                    .then((data: {status: string, token: string | null }) => {
                        if (data.status !== 'success' || data.token === null) {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Erro',
                                // Set text as html to allow for line breaks
                                html: 'Ocorreu um erro ao iniciar sessão.<br> Por favor, tente novamente.'
                            });
                            return;
                        }
                        localStorage.setItem('token', data.token);
                        setTimeout(() => {
                            this.loggingIn = false;
                            window.location.reload();
                            this.$swal.close();
                        }, 1000);
                    })
                    .catch((error) => {
                        this.loggingIn = false;
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Erro',
                            html: 'Credenciais inválidas.<br> Por favor, tente novamente.'
                        });
                    });
            } else {
                this.loggingIn = false;
                this.$swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Por favor, preencha a senha.',
                });
            }
        } else {
            this.loggingIn = false;
            this.$swal.fire({
                icon: 'error',
                title: 'Erro',
                text: 'Por favor, preencha um email válido.',
            });
        }
    }
  },
});
</script>
<style scoped>
/* Align the container vertical content to the middle of the screen. */
.container.login {
    justify-content: center;
    align-items: center;
}


.jumbotron {
    margin-bottom: 0;
}

.jumbotron-fluid {
    padding: 0 !important;
    background-color: var(--primary-color);
    background-size: cover;
    background-position: center;
    min-height: 300px;
    margin: unset !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 1);
}

.jumbotron h1 {
    color: white;
    font-size: 4.5rem;
    font-weight: 700;
    text-align: left;
}

.jumbotron h2 {
    color: white;
    font-size: 3rem;
    font-weight: 700;
    text-align: left;
}

.jumbotron h3 {
    color: white;
    font-size: 2rem;
    font-weight: 400;
    text-align: left;
}

@media (min-width: 1200px) and (max-width: 1701px) {
    .jumbotron h1 {
        font-size: 3.5rem;
    }

    .jumbotron h2 {
        font-size: 2.5rem;
    }

    .jumbotron h3 {
        font-size: 1.5rem;
    }
    
}
</style>