import axios from "axios";

// Define API URL
const API_URL = process.env.VUE_APP_API_URL;


// Define axios instance
const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

export class SearchService {
    static async search(query: string, latitude: number, longitude: number) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.post('/v1/search', { query: query, latitude: latitude, longitude: longitude }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200 ? await response.data : false;
        } catch (error) {
            return false;
        }
    }

    static async getShopProfileDetails(shopProfileId: number) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.get(`/v1/shop-profile/${shopProfileId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200 ? await response.data : false;
        } catch (error) {
            return false;
        }
    }
}