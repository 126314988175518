<template>
  <div class="tooltip-container" @mouseenter="showTooltip" @mouseleave="hideTooltip">
    <slot></slot>
    <div v-if="visible" class="tooltip-box" :style="tooltipStyle">
      {{ text }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'TooltipComponent',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  setup() {
    const visible = ref(false);
    const tooltipStyle = ref({});

    const showTooltip = (event: MouseEvent) => {
      visible.value = true;
      
      const targetElement = event.currentTarget as HTMLElement;
      const targetRect = targetElement.getBoundingClientRect();

      tooltipStyle.value = {
        top: `${targetRect.top + window.scrollY - targetRect.height}px`, // Position above the element
        left: `${targetRect.left + window.scrollX + targetRect.width / 2}px`, // Center the tooltip
        transform: 'translateX(-50%) translateY(-100%)', // Adjust for proper alignment
      };
    };

    const hideTooltip = () => {
      visible.value = false;
    };

    return {
      visible,
      tooltipStyle,
      showTooltip,
      hideTooltip,
    };
  },
});
</script>

<style scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-box {
  position: fixed;
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  pointer-events: none;
  margin-top: 20px;
  transform: translateX(-50%) translateY(-100%); /* Default alignment */
}
</style>
