<template>
    <h2>Recover password</h2>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'recoverPasswordView'
});
</script>
