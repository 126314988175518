<template>
    <div class="container-fluid mt-5">
        <div class="row">
            <div class="col-12 col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2 col-lg-10 offset-xxl-1 offset-xl-1 offset-lg-1">
                <h2><b>Os mais procurados em Braga</b></h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12 col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2 col-lg-10 offset-xxl-1 offset-xl-1 offset-lg-1">
                <div class="row">
                    <div class="col-12 col-xxl-3 col-xl-6 col-lg-6 col-sm-6 col-md-" v-for="item in mostViewed" :key="item.id">
                        <div class="card shopCard">
                            <img :src="item.image" class="card-img-top img-fluid" alt="...">
                            <div class="card-body">
                                <p class="text-center text-muted">{{ item.serviceCategory }}</p>
                                <h5 class="card-title">
                                    {{ item.shopName }}
                                </h5>
                                <p class="text-muted">{{ item.address }}</p>
                                <p class="card-text promotionMessage mt-3 mb-3">
                                    {{ item.discountMessage }}
                                </p>
                                <div class="card-text text-center">
                                    <div>
                                        <!-- Full stars -->
                                        <i v-for="n in Math.floor(item.reviews)" :key="'full-star-' + n" class="fas fa-star"></i>

                                        <!-- Half star (if applicable) -->
                                        <i v-if="item.reviews % 1 >= 0.5" :key="'half-star'" class="fas fa-star-half-alt"></i>

                                        <!-- Empty stars -->
                                        <i v-for="n in 5 - Math.ceil(item.reviews)" :key="'empty-star-' + n" class="far fa-star"></i>
                                    </div>
                                    <span class="text-small">({{ Math.floor(item.reviews) }} em {{ item.reviewsCount }} Reviews)</span>
                                    <div class="btn2 mt-2">
                                        <button href="#" class="btn btn-primary" style="float: right; width:100%;">Pedir Orçamento</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'MostViewed',
    data() {
        return {
            mostViewed: [
                {
                    id: 1,
                    shopName: 'Norauto - Braga Retail Center',
                    serviceCategory: 'Mecânico - Eletricista',
                    address: 'Rua de São Vicente, 4710-306 Braga',
                    discountMessage: 'Desconto de 10% na mão de obra',
                    reviews: 3.5,
                    reviewsCount: 100,
                    image: 'https://jornaldasoficinas.com/pt/wp-content/uploads/2023/09/Norauto-chega-a-terras-Nortenhas-.jpeg',
                },
                {
                    id: 2,
                    shopName: 'Norauto - Braga Retail Center',
                    serviceCategory: 'Mecânico - Eletricista',
                    address: 'Rua de São Vicente, 4710-306 Braga',
                    discountMessage: 'Desconto de 10% na mão de obra',
                    reviews: 4.5,
                    reviewsCount: 100,
                    image: 'https://jornaldasoficinas.com/pt/wp-content/uploads/2023/09/Norauto-chega-a-terras-Nortenhas-.jpeg',
                },
                {
                    id: 3,
                    shopName: 'Norauto - Braga Retail Center',
                    serviceCategory: 'Mecânico - Eletricista',
                    address: 'Rua de São Vicente, 4710-306 Braga',
                    discountMessage: 'Desconto de 10% na mão de obra',
                    reviews: 4.5,
                    reviewsCount: 100,
                    image: 'https://jornaldasoficinas.com/pt/wp-content/uploads/2023/09/Norauto-chega-a-terras-Nortenhas-.jpeg',
                },
                {
                    id: 4,
                    shopName: 'Norauto - Braga Retail Center',
                    serviceCategory: 'Mecânico - Eletricista',
                    address: 'Rua de São Vicente, 4710-306 Braga',
                    discountMessage: 'Desconto de 10% na mão de obra',
                    reviews: 4.5,
                    reviewsCount: 100,
                    image: 'https://jornaldasoficinas.com/pt/wp-content/uploads/2023/09/Norauto-chega-a-terras-Nortenhas-.jpeg',
                }
            ],
        };
    },
});
</script>
<style scoped>
h2 {
    color: var(--secundary-color)
}

.img-fluid {
    width: 100% !important;
    height: 250px !important;
    object-fit: cover;
}

.shopCard {
    border: 3px solid var(--secundary-color) !important;
    box-shadow: 0px 0px 10px 0px rgb(0,0,0,0.5);
    border-radius: 10px;
    margin-bottom: 20px;

    transition: ease-in-out 0.1s;   
    cursor: pointer;
}

.shopCard:hover {
    border: 3px solid var(--primary-color);
    box-shadow: 0px 0px 10px 0px var(--primary-color);
    transform: scale(1.05);
}

.card-title {
    color: var(--secundary-color);
    font-weight: bold;
}

.promotionMessage {
    color: var(--primary-color);
    font-size: 12px;
    border-left: 1px solid var(--primary-color);
    padding-left: 10px;
    width: auto;
    margin: auto;
    text-align: center;
}

.fa-star, .fa-star-half-alt, .fa-star {
    color: var(--primary-color) !important;
}
</style>