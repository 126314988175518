<template>
    <div v-if="loading" class="d-flex justify-content-center align-items-center" style="height: 100vh;">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div v-else>
        <smallSearchComponent :query="query" :location="location" :nobg="true" :showPreviousButton="showPreviousBTN" />
        <div class="height-30px"></div>
        <div class="jumbotron-fluid" ref="jumbo"></div>
        <div class="container">
            <div class="row">
                <div class="col-12 companyInfoBar">
                    <div class="row">
                    <div class="col-2">
                        <img :src="shopProfile?.logo" alt="Logo" class="img-fluid">
                    </div>
                    <div class="col-4 mt-4 companyNameAddress">
                        <h2>{{ shopProfile?.name }}</h2>
                        <p>
                        <i class="fas fa-map-marker-alt"></i> {{ shopProfile?.address }} {{ shopProfile?.zip }} {{ shopProfile?.city }}<br>
                        <i class="fas fa-phone"></i> {{ shopProfile?.phone }} | <i class="fas fa-inbox"></i> {{ shopProfile?.email }}<br>
                        </p>
                    </div>
                    <div class="col-2 mt-2 offset-1 text-center">
                        <div class="pt-5" v-if="!loading" style="display: inline-block;">
                            <!-- Full stars -->
                            <i v-for="n in Math.floor(validScore())" :key="'full-star-' + n" class="fas fa-star"></i>

                            <!-- Half star (if applicable) -->
                            <i v-if="validScore() % 1 >= 0.5" :key="'half-star'" class="fas fa-star-half-alt"></i>

                            <!-- Empty stars -->
                            <i v-for="n in 5 - Math.ceil(validScore())" :key="'empty-star-' + n" class="far fa-star"></i>
                        </div>
                        <span class="text-small d-inline-block">{{ Math.floor(validScore()) }}* em {{ shopProfile?.reviews?.length }} Review(s)</span>
                    </div>
                    <div class="col-3 mt-6 text-right">
                        <div class="row">
                            <div class="col-10">
                                <button class="btn btn-primary">Pedir Orçamento</button>
                            </div>
                            <div class="col-2">
                                <i class="far fa-heart fa-2x fav_btn"></i>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="shopProfile !== null">
                <ServicesAvailable :shop="shopProfile" :data="organizeServicesByCategory()"></ServicesAvailable>
            </div>
            <div class="row" v-if="shopProfile !== null">
                <ShopLocationMap :shop="shopProfile"></ShopLocationMap>
            </div>
            <div class="row" v-if="shopProfile !== null">
                <ShopReviews :shop="shopProfile"></ShopReviews>
            </div>
        </div>
    </div>
</template>
  
<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { SearchService } from '@/services/searchService';
import { ShopProfile, ShopReview, ShopService, ShopServiceCategory } from '@/interfaces/shopProfileInterface';
import { nextTick } from 'vue';
import smallSearchComponent from '@/components/search/smallSearchComponent.vue';
import ServicesAvailable from '@/components/page/shop/servicesAvailable.vue';
import ShopLocationMap from '@/components/page/shop/shopLocationMap.vue';
import ShopReviews from '@/components/page/shop/shopReviews.vue';

export default defineComponent({
    name: 'ShopPage',
    data() {
        return {
            loading: false,
            shopProfile: null as ShopProfile | null,
            wallpaper: '/public/img/driverwise_banner.jpg',
            query: '',
            location: '',
            showPreviousBTN: false,
        };
    },
    components: {
        smallSearchComponent,
        ServicesAvailable,
        ShopLocationMap,
        ShopReviews
    },
    async mounted() {
        this.loading = true;
        const route = useRoute();
        const router = useRouter();
        let shopProfileID = route.params.id;
        if (!shopProfileID) {
            router.back();
            return;
        }

        shopProfileID = shopProfileID.toString();

        this.shopProfile = await SearchService.getShopProfileDetails(parseInt(shopProfileID))
        if (this.shopProfile === null) {
            router.back();
            return;
        }

        console.log(this.organizeServicesByCategory());
        this.loading = false;
        this.wallpaper = this.shopProfile.wallpaper || '/img/driverwise_banner.jpg';

        this.query = window.localStorage.getItem('query') || '';
        this.location = window.localStorage.getItem('location') || '';

        if (this.query !== '' && this.location !== '') {
            this.showPreviousBTN = true;
        }

        document.title = `DriverWise - ${this.shopProfile.name}`;
    },
    watch: {
        shopProfile: {
            immediate: true,
            async handler() {
                await nextTick();
                const jumbotronElement = this.$refs.jumbo as HTMLElement;
                if (!jumbotronElement) {
                    return;
                }
                jumbotronElement.style.backgroundImage = `url('${this.wallpaper}')`;
            },
        },
    },
    methods: {
        formatDate(isoDate: string): string {
            const date = new Date(isoDate);

            const day = date.getUTCDate().toString().padStart(2, '0');
            const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
            const year = date.getUTCFullYear();
            return `${day}/${month}/${year}`;
        },
        validScore() {
            if (!this.shopProfile) {
                return 0;
            }
            const score = this.obtainReviewsScore();
            return isNaN(score) || score < 0 ? 0 : Math.min(score, 5);
        },
        obtainReviewsScore() {
            let totalScore = 0;
            let totalReviews = 0;

            if (!this.shopProfile) {
                return 0;
            }

            if (this.shopProfile.reviews === null) {
                return 0;
            }

            this.shopProfile.reviews.forEach((review: ShopReview) => {
                totalScore += review.rating;
                totalReviews++;
            });

            return totalScore / totalReviews;
        },
        organizeServicesByCategory() {
            if (!this.shopProfile || !this.shopProfile.services) {
                return [];
            }

            const categoryMap: { [key: string]: { category: string, image: string, services: ShopService[] } } = {};

            this.shopProfile.services.forEach((service: ShopService) => {
                service.category.forEach((cat: ShopServiceCategory) => {
                if (!categoryMap[cat.name]) {
                    categoryMap[cat.name] = {
                    category: cat.name,
                    image: cat.image,
                    services: []
                    };
                }
                categoryMap[cat.name].services.push(service);
                });
            });

            return Object.values(categoryMap);
        }

    }
});
</script>
  
<style scoped>
.spinner-border {
width: 3rem;
height: 3rem;
color: #EA5455;
}

.visually-hidden {
position: absolute;
width: 1px;
height: 1px;
padding: 0;
margin: -1px;
overflow: hidden;
clip: rect(0, 0, 0, 0);
white-space: nowrap;
border-width: 0;
}

.jumbotron {
margin-bottom: 0;
}

.jumbotron-fluid {
padding: 0 !important;
background-size: cover;
background-position: center;
height: 500px;
padding-top: 100px !important;
padding-bottom: 100px !important;
margin: unset !important;
}

.companyInfoBar {
background-color: #ffffff;
color: black;
border: 1px solid #d7d7d7;
padding: 10px;
margin-top: -80px;
border-radius: 10px;
}

.img-fluid {
max-width: 100%;
height: auto;
mix-blend-mode: multiply;
}

.companyNameAddress > h2 {
color: #414141;
}

.companyNameAddress > p {
color: #989898;
}

.fas.fa-map-marker-alt, .fas.fa-phone, .fas.fa-star, .fas.fa-star-half-alt, .far.fa-star, .fas.fa-inbox {
    color: #EA5455;
}

.text-right {
text-align: right;
padding-right: 50px;
}

.mt-6 {
margin-top: 3.5rem;
}

.fav_btn {
color: #EA5455;
}

.fav_btn:hover {
    cursor: pointer;
    transition: all 0.3s;
}

.searchBox > .background-image {
    background-image: unset !important;
    background-color: #3c3c3c
}

.height-30px {
    height: 30px;
    width: 100%;
}
</style>
