<template>
    <smallSearchComponent @search="updateLocationData" :nobg="false"></smallSearchComponent>
    <div class="container mt-5 mb-5">
        <h1>{{ searchH1TextGeneration() }}</h1>
        <h5 class="info-text">{{ searchH5TextGeneration() }}</h5>
    </div>
    <div class="row">
        <div class="col-12 col-xl-6 col-xxl-6 col-lg-6 order-1 order-xl-0 order-xxl-0 order-lg-0" style="padding: unset !important; margin: unset !important;">
            <mapComponent :big_marker="result_id" :location="location" @location="updatedLocationMap" :locations="markers"></mapComponent>
        </div>
        <div class="col-12 col-xl-6 col-xxl-6 col-lg-6 order-0 order-xl-1 order-xxl-1 order-lg-1" style="padding: unset !important; margin: unset !important;max-height: calc(100vh - 200px); overflow-y: auto;" v-if="markers.length > 0">
            <div class="row" style="padding: unset !important; margin: unset !important;">
                <div class="col-12 searchResult" v-for="marker in markers" v-bind:key="marker.id" @click="visitShop(marker.id)" @mouseover="makeGPSLocationBig(marker.id)">
                    <div class="row">
                        <div class="col-3 pt-3">
                            <img :src="marker.logo" class="searchResultLogo" alt="logo">
                        </div>
                        <div class="col-7">
                            <h1 class="text-dark">{{ marker.name }}</h1>
                            <p class="text-gray">
                                <i class="fas fa-map-marker-alt primary-color"></i> {{ marker.address }} {{ marker.zip }} {{ marker.city }}
                            </p>
                            <p class="text-gray">
                                <i class="fas fa-phone primary-color"></i> <a :href="'tel:' + marker.phone">{{ marker.phone }}</a><br>
                                <i class="fas fa-inbox primary-color"></i> <a :href="'mailto:' + marker.email">{{ marker.email }}</a><br>
                                <i class="fas fa-globe primary-color" v-if="marker.website"></i> <span v-if="marker.website"><a :href="marker.website" target="_blank">{{ marker.website }}</a></span>
                            </p>
                        </div>
                        <div class="col-2 pt-5 text-center">
                            <div>
                                <!-- Full stars -->
                                <i v-for="n in Math.floor(validScore(marker))" :key="'full-star-' + n" class="fas fa-star"></i>

                                <!-- Half star (if applicable) -->
                                <i v-if="validScore(marker) % 1 >= 0.5" :key="'half-star'" class="fas fa-star-half-alt"></i>

                                <!-- Empty stars -->
                                <i v-for="n in 5 - Math.ceil(validScore(marker))" :key="'empty-star-' + n" class="far fa-star"></i>
                            </div>
                            <span class="text-small">{{ Math.floor(validScore(marker)) }}* em {{ marker.reviews.length }} Review(s)</span>
                            <div class="btn2 mt-5">
                                <button href="#" class="btn btn-primary" style="float: right; width:100%;">Pedir Orçamento</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-xl-6 col-xxl-6 col-lg-6  order-0 order-xl-1 order-xxl-1 order-lg-1" v-if="markers == null || markers?.length == 0 && loading == false">
            <!-- Make a jumbotron div and add "A sua pesquisa não devolveu resultados" -->
            <div class="row">
                <div class="col-8 offset-2 mt-5">
                    <div class="jumbotron text-center">
                        <h1 class="display-4">Sem Resultados</h1>
                        <p class="lead">
                            Por favor, tente novamente com outros termos de pesquisa.<br>
                            Ou noutra localização.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import smallSearchComponent from '../../components/search/smallSearchComponent.vue';
import mapComponent from '../../components/search/mapComponent.vue';
import { SearchService } from '@/services/searchService';
import { services } from '@/interfaces/searchOptions';

export default defineComponent({
    name: 'searchPage',
    components: {
        smallSearchComponent,
        mapComponent
    },
    data() {
        return {
            location: '',
            query: '',
            markers: [],
            result_id: 0,
            loading: false
        };
    },
    methods: {
        searchH1TextGeneration() {
            return this.query ? `Resultados para "${this.getQueryOption()}" em ${this.location}` : 'Resultados';
        },
        searchH5TextGeneration() {
            return this.markers.length > 0 ? `Encontrámos ${this.markers.length} resultados para a sua pesquisa` : 'Sem resultados';
        },
        getQueryOption() {
            const queryOption = services.find((service) => service.value === this.query);
            return queryOption ? queryOption.name : '';
        },

        validScore(marker: any) {
            const score = this.obtainReviewsScore(marker);
            return isNaN(score) || score < 0 ? 0 : Math.min(score, 5);
        },
        obtainReviewsScore(shopProfile: any) {
            let totalScore = 0;
            let totalReviews = 0;

            shopProfile.reviews.forEach((review: any) => {
                totalScore += review.rating;
                totalReviews++;
            });

            return totalScore / totalReviews;
        },
        async updateLocationData(query: string, location: string) {
            this.query = query;
            this.location = location;

            // Redirect again to pesquisar
            await this.$router.push({
                name: 'pesquisa',
                query: {
                    query: this.query,
                    location: this.location,
                }
            });
        },
        updatedLocationMap(data: any) {
            if (data.lat === undefined || data.lng === undefined) {
                return;
            }

            SearchService.search(this.query, data.lat, data.lng)
                .then((response) => {
                    this.markers = response;
                    this.loading = false;
                })
                .catch((error) => {
                    console.error(error);
                    this.loading = false;
                });
        },
        makeGPSLocationBig(id: number) {
            this.result_id = id;
        },
        visitShop: function(id: number) {
            window.localStorage.setItem('location', this.location);
            window.localStorage.setItem('query', this.query);
            this.$router.push({ name: 'establecimento', params: { id: id.toString() } });
        },
    },
    mounted() {
        const query = this.$route.query.query;
        const location = this.$route.query.location;

        this.query = query as string;
        this.location = location as string;

        document.title = 'DriverWise - Pesquisa';
    }
});
</script>

<style scoped>

.searchResult {
    border-bottom: 1px solid #dfdfdf;
    padding: 10px;
    transition: all 0.3s;
}

.searchResult:hover {
    background-color: #e3e3e3;
    cursor: pointer;
}

.searchResult .row {
    margin: 10px;
}

.searchResultLogo {
    width: 100%;
    height: auto;
    mix-blend-mode: multiply;
}

.text-gray {
    color: #a7a7a7;
}

.btn.btn-primary {
    color: white !important;
    margin-right: 10px;
}

.opbtns .btn1 {
    padding-left: 20px;
}

.opbtns .btn2 {
    padding-right: 10px;
}

.text-small {
    font-size: 12px;
}

.primary-color {
    color: var(--primary-color);
}

a {
    color: var(--info-text-color) !important;
}

.fa-star, .fa-star-half-alt {
    color: #EF4E22 !important;
}
</style>
  