<template>
  <button
    type="button"
    :style="style"
    :class="'btn btn-primary ' + extraClasses"
    @click="handleClick"
  >
    {{ text }}
  </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'PrimaryButton',
  props: {
    text: {
      type: String,
      required: true,
    },
    style: {
      type: String,
      default: '',
    },
    extraClasses: {
      type: String,
      default: '',
    },
    click: {
      type: Function as PropType<(event: MouseEvent) => void>,
      required: true,
    },
  },
  methods: {
    handleClick(event: MouseEvent) {
      this.click(event);
    },
  },
});
</script>
