<template>
    <button type="button" class="btn google-btn btn-block col-12" @click="click">
        <i class="fab fa-google mr-2"></i> Iniciar Sessão com Google
    </button>
</template>
<script lang="ts">
export default {
  name: 'GoogleSocialLogin',
  props: {
    click: {
      type: Function,
      required: true
    }
  }
}
</script>