import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("input", {
      type: "password",
      class: "form-control",
      id: "password",
      "aria-describedby": "passwordHelp",
      placeholder: "Introduza a palavra passe",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event))
    }, null, 512), [
      [_vModelText, _ctx.password]
    ])
  ]))
}