<template>
    <div class="jumbotron jumbotron-fluid">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xxl-8 col-xl-8 col-12 offset-xxl-2 offset-xl-2 pb-5">
                    <h1 class="d-none d-xl-block">A gestão inteligente do seu <br> automóvel com a DriverWise!</h1>
                    <h2 class="d-block d-xl-none">A gestão inteligente do seu <br> automóvel com a DriverWise!</h2>
                    <h3>Mais poupança, mais inteligente, mais simples, mais DriverWise!</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <Search></Search>
    </div>
    <div class="container-fluid mt-5">
        <CarInfo></CarInfo>
    </div>
    <div class="container-fluid mt-5">
        <div class="row ">
            <div class="col-12 col-xxl-8 offset-xxl-2">
                <img src="https://i.pinimg.com/originals/86/24/00/86240022e8bc8c661dbb2eeace972756.jpg" class="img-fluid" alt="Responsive image">
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <Mostviewed></Mostviewed>
    </div>
    <div class="container-fluid">
        <Recentlyviewed></Recentlyviewed>
    </div>
  </template>

<script lang="ts">
import Search from '@/components/page/home/search.vue'
import CarInfo from '../components/page/home/carInfo.vue'
import { defineComponent } from 'vue';
import Mostviewed from './homepage/mostviewed.vue';
import Recentlyviewed from './homepage/recentlyviewed.vue';

export default defineComponent({
    components: {
        CarInfo,
        Search,
        Mostviewed,
        Recentlyviewed
    },
    name: 'HomePage',
    data() {
        return {
        name: 'DriverWise',
        dropdownVisible: false,
        };
    },
    mounted() {
        document.title = 'DriverWise - Dashboard';
        const token = localStorage.getItem('token');
        if (token) {
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        this.name = decodedToken.name;
        }
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        toggleDropdown(event: MouseEvent) {
            event.stopPropagation();
            this.dropdownVisible = !this.dropdownVisible;
        },
        handleClickOutside(event: MouseEvent) {
            const target = event.target as HTMLElement;
            if (!target.closest('#navbarDropdown')) {
                this.dropdownVisible = false;
            }
        },
    },
});
</script>

<style scoped>
.jumbotron {
    margin-bottom: 0;
}

.jumbotron-fluid {
    padding: 0 !important;
    background-color: var(--primary-color);
    background-size: cover;
    background-position: center;
    min-height: 300px;
    margin: unset !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 1);
}

.jumbotron h1 {
    color: white;
    font-size: 4.5rem;
    font-weight: 700;
    text-align: left;
}

.jumbotron h2 {
    color: white;
    font-size: 3rem;
    font-weight: 700;
    text-align: left;
}

.jumbotron h3 {
    color: white;
    font-size: 2rem;
    font-weight: 400;
    text-align: left;
}

@media (min-width: 1200px) and (max-width: 1701px) {
    .jumbotron h1 {
        font-size: 3.5rem;
    }

    .jumbotron h2 {
        font-size: 2.5rem;
    }

    .jumbotron h3 {
        font-size: 1.5rem;
    }
    
}
</style>
  