<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-1 offset-1 pt-5 d-none d-xxl-block text-right" style="padding-right: unset;text-align: right;" v-if="vehicles.length > 1">
                <i class="fas fa-chevron-left pt-5 fa-7x" @click="switchVehicle"></i>
            </div>
            <div class="col-12 col-xxl-8" :class="{'offset-2': vehicles.length <= 1}">
                <div class="card" :style="{ filter: isLoading ? 'blur(7px)' : 'none' }">
                    <div class="row">
                        <div class="col-12 col-xl-4 col-xxl-4 col-lg-4 col-sm-12 col-xs-12 col-xss-12" style="padding: unset;">
                            <img :src="selectedCar.photo || '/img/car-placeholder.png'" alt="Car" class="img-fluid carImg" />
                        </div>
                        <div class="col-12 col-xl-5 col-xxl-5 col-lg-4 col-sm-12 pt-4 pb-3 carData" style="padding-left: 30px;">
                            <h2>{{ selectedCar.brand.name }} {{ selectedCar.model }} ({{ selectedCar.subModel }})</h2>
                            <h3 class="info-text mt-3">{{ selectedCar.licensePlate }}</h3>
                            <h3 class="info-text mt-3">{{ selectedCar.vinNumber }}</h3>
                            <h3 class="info-text mt-3">{{ selectedCar.manufacturerDate }}</h3>
                            <h3 class="info-text mt-3">{{ selectedCar.mileage }} KM</h3>
                        </div>
                        <div class="col-12 col-lg-4 col-xxl-3 col-xl-3 col-sm-12 carStatus" style="padding-left: 30px; padding-right: 30px; padding-top: 70px;">
                            <div class="row">
                                <div class="col-3 text-center icon-status">
                                    <Tooltip text="Imposto de circulação">
                                        <img src="/img/icons/icon-iuc.svg" height="50px">
                                    </Tooltip>
                                </div>
                                <div class="col-3 offset-1 text-center icon-status" :class="{}">
                                    <Tooltip text="Seguro">
                                        <img src="/img/icons/icon_seguro.svg" height="50px">
                                    </Tooltip>
                                </div>
                                <div class="col-3 offset-1 text-center icon-status">
                                    <Tooltip text="Manutenção">
                                        <img src="/img/icons/icon_revisao.svg" height="50px">
                                    </Tooltip>
                                </div>
                            </div>
                            <div class="row pt-5 mb-5">
                                <div class="col-3 text-center icon-status">
                                    <Tooltip text="Inspecção">
                                        <img src="/img/icons/ico_inspection.svg" height="50px" />
                                    </Tooltip>
                                </div>
                                <div class="col-3 offset-1 text-center icon-status">
                                    <Tooltip text="Pneus à retaguarda">
                                        <img src="/img/icons/icon_pneutras.svg" height="50px" />
                                    </Tooltip>
                                </div>
                                <div class="col-3 offset-1 text-center icon-status">
                                    <Tooltip text="Pneus à frente">
                                        <img src="/img/icons/icon_pneusfrente.svg" height="50px" />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-1 pt-5 d-none d-xxl-block" v-if="vehicles.length > 1">
                <i class="fas fa-chevron-right pt-5 fa-7x" @click="switchVehicle"></i>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Vehicle } from '@/dtos/userService/vehicleInterface';
import { UserService } from '@/services/userService';
import { defineComponent } from 'vue';
import Tooltip from '@/components/shared/components/tooltip.vue';
import { VehiclesService } from '@/services/vehiclesService';

export default defineComponent({
    name: 'CarInfo',
    components: {
        Tooltip
    },
    mounted() {
        const userService = new UserService();

        UserService.obtainVehicles().then((response) => {
            if (!response) {
                return;
            }

            this.vehicles = response;
        });

        userService.obtainDefaultVehicle().then((response) => {
            if (!response) {
                this.$swal.fire({
                    // Create a quick alert to inform the user that he needs to add a vehicle
                    html: 'Parece que ainda não adicionou nenhuma viatura. Por favor, adicione uma viatura para continuar.',
                    icon: 'info',
                    confirmButtonText: 'Adicionar viatura',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    // Style the confirm button to match the primary color
                    confirmButtonColor: '#EA7C69',
                }).then(() => {
                    this.$router.push({ name: 'profile.vehicles' });
                });
                return;
            }

            this.selectedCar = response;

            // Convert dates to a more readable format like DD-MM-YYYY
            this.selectedCar.registrationDate = new Date(this.selectedCar.registrationDate).toLocaleDateString();
            this.selectedCar.manufacturerDate = new Date(this.selectedCar.manufacturerDate).toLocaleDateString();
            this.selectedCar.insuranceStartDate = new Date(this.selectedCar.insuranceStartDate).toLocaleDateString();
            this.selectedCar.inspectionDate = new Date(this.selectedCar.inspectionDate).toLocaleDateString();
            this.isLoading = false;
        });
    },
    data() {
        return {
            vehicles: [] as Vehicle[],
            selectedCar: {
                id: 0,
                brand: { id: 0, name: 'Toyota' },
                model: 'Yaris',
                subModel: '1.0',
                licensePlate: '00-00-00',
                registrationDate: '01-01-2000',
                manufacturerDate: '01-01-2000',
                insuranceStartDate: '01-01-2000',
                inspectionDate: '01-01-2000',
                vinNumber: '00000000000000000',
                mileage: 0,
            } as unknown as Vehicle,
            isLoading: true
        }
    },
    methods: {
        switchVehicle() {
            this.isLoading = true;
            setTimeout(() => {
                const currentIndex = this.vehicles.findIndex((vehicle) => vehicle.id === this.selectedCar.id);
                const nextIndex = currentIndex + 1;

                if (nextIndex >= this.vehicles.length) {
                    this.selectedCar = this.vehicles[0];
                    VehiclesService.setDefaultVehicle(this.selectedCar.id).then(() => {
                        this.isLoading = false;
                    });
                    return;
                }

                this.selectedCar = this.vehicles[nextIndex];
                VehiclesService.setDefaultVehicle(this.selectedCar.id).then(() => {
                    this.isLoading = false;
                });
            }, 100)
        }
    }
})
</script>
<style scoped>
.card {
    opacity: 0.9;
    transition: all 0.3s;
    border-radius: 10px;
}

.card .card-header {
    border-bottom: unset;
    background-color: unset;
}

.card .card-header .title {
    color: #EA7C69;
    margin-left: 10px;
}

.card .card-header .license-plate {
    color: #EA7C69;
    text-align: right;
    float: right;
    margin-top: 10px;
    margin-right: 10px;
}

.fa-chevron-right, .fa-chevron-left {
    color: #EA7C69;
    cursor: pointer;
    transition: all 0.3s;
}

.fa-chevron-right:hover, .fa-chevron-left:hover {
    color: #EF4E22;
}

.justify-text-right {
    text-align: right;
}

.card {
    border: 1px solid #dadada;
    border-left: unset;
    border-right: unset;
    border-top: unset;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


.img-fluid {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 100%;
}

.icon-status {
    color: white;
    background-color: var(--primary-color);
    border-radius: 10px;
    padding: 10px;
}

.icon-status.ok {
    background-color: green;
}

.icon-status.warning {
    background-color: orange;
}

.icon-status.error {
    background-color: #FF0000;
}

/* Add @media queries here to make sure carData and carStatus are aligned */
@media (min-width: 1200px) {
    .carData, .carStatus {
        padding-top: 80px !important;
    }
}

@media (max-width: 992px) {
    .carData, .carStatus {
        padding-top: 100px !important;
    }
}

@media (max-width: 768px) {
    .carData, .carStatus {
        padding-top: 100px !important;
    }
}

@media (max-width: 576px) {
    .carData, .carStatus {
        padding-top: 100px !important;
    }
}

/** make sure .carImg maintains full height of div. */
.carImg {
    height: 100%;
    object-fit: cover;
}
</style>