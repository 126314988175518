<template>
    <div>
        <input type="password" class="form-control" id="password" aria-describedby="passwordHelp" placeholder="Introduza a palavra passe" v-model="password" />
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'PasswordInput',
    data() {
        return {
            password: ''
        }
    },
    watch: {
        password() {
            this.$emit('onChange', this.password);
        }
    },
})
</script>