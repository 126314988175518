<template>
    <div class="mapContainer">
        <l-map ref="map" :zoom="zoom" :center="[lat, lng]" @update:center="mapMoved">
            <l-tile-layer
                url="https://mt0.google.com/vt/hl=pt&x={x}&y={y}&z={z}&s=Ga"
                layer-type="base"
                name="GoogleMaps"
                :options="{ zoomControl: true, attributionControl: true }"
            ></l-tile-layer>
            <template v-for="location in locations" :key="location.id">
                <l-marker :lat-lng="[location.gps_lat, location.gps_lng]">
                    <LIcon :iconUrl="require('@/assets/map_icons/repair_shop.png')" :iconSize="[64, 64]" :iconAnchor="[12, 41]" />
                    <l-popup>
                        <div>
                            <p><b>Nome:</b> {{ location.name }}</p>
                            <p><b>Morada:</b> {{ location.address }}</p>
                        </div>
                    </l-popup>
                </l-marker>
            </template>
        </l-map>
    </div>
</template>

<script lang="ts">
import "leaflet/dist/leaflet.css";
import { LMap, LIcon, LMarker, LTileLayer, LPopup } from "@vue-leaflet/vue-leaflet";
import axios from "axios";
import { defineComponent } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
        LIcon
    },
    props: {
        locations: {
            type: Array,
            required: false,
            default: () => [],
        },
        big_marker: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            zoom: 5,
            lat: 47.41322,
            lng: -1.219482,
            isResultOverMarker: 0,
            searchLat: 0,
            searchLng: 0,
        };
    },
    async mounted() {
        const route = useRoute();
        const location = route.query.location as string | null;

        if (location) {
            await this.updateLocation(location);
        }
    },
    watch: {
        async $route() {
            const location = this.$route.query.location as string | null;

            if (location) {
                await this.updateLocation(location);
            }
        },
        // Watch for changes in the gps_coords prop
        big_marker: {
            handler(new_marker_id: number) {
                if (this.isResultOverMarker !== new_marker_id) {
                    this.isResultOverMarker = new_marker_id;
                }

                if (new_marker_id === 0) {
                    this.isResultOverMarker = 0;
                }
            },
            deep: true,
        },
    },
    methods: {
        async updateLocation(location: string) {
            try {
                const response = await axios.get(`https://nominatim.openstreetmap.org/search?q=${location}&format=json`);
                if (response.data && response.data.length > 0) {
                    const { lat, lon } = response.data[0];
                    this.lat = parseFloat(lat);
                    this.lng = parseFloat(lon);
                    this.searchLat = parseFloat(lat);
                    this.searchLng = parseFloat(lon);
                    this.zoom = 12;
                } else {
                    throw new Error("Location not found");
                }
            } catch (error) {
                // Obtain user's location from IP address
                const response = await axios.get("https://ipapi.co/json");
                if (response.data) {
                    const { latitude, longitude } = response.data;
                    this.lat = parseFloat(latitude);
                    this.lng = parseFloat(longitude);
                    this.searchLat = parseFloat(latitude);
                    this.searchLng = parseFloat(longitude);
                    this.zoom = 12;
                }
                console.error("Error fetching location data:", error);
                this.$swal.close();
            }
            // Obtain square area of the location
            const response = await axios.get(
                `https://nominatim.openstreetmap.org/search?q=${location}&format=json&limit=1`
            );
            if (response.data && response.data.length > 0) {
                const { lat, lon } = response.data[0];
                const response2 = await axios.get(
                    `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`
                );
                if (response2.data) {
                    const { boundingbox } = response2.data;
                    this.$emit("location", {
                        lat: parseFloat(lat),
                        lon: parseFloat(lon),
                        boundingbox: boundingbox.map((coord: string) => parseFloat(coord)),
                    });
                    this.$swal.close();
                }
            }
        },
        async mapMoved(event: any) {
            try {
                const lat = event.lat !== undefined ? event.lat : this.lat;
                const lng = event.lng !== undefined ? event.lng : this.lng;
                
                if (event.lat && event.lng) {
                    this.lat = event.lat;
                    this.lng = event.lng;
                }

                this.$emit("location", {
                    lat: parseFloat(lat),
                    lng: parseFloat(lng),
                });
            } catch (error) {
                this.$swal.close();
                this.$swal.fire ({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Erro ao obter a localização',
                });
                console.error("Error fetching location data:", error);
            }
        }
    }
});
</script>

<style>
.mapContainer {
    height: 80vh;
    width: 100%;
}
</style>
