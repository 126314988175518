import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "tooltip-container",
    onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showTooltip && _ctx.showTooltip(...args))),
    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.hideTooltip && _ctx.hideTooltip(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_ctx.visible)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "tooltip-box",
          style: _normalizeStyle(_ctx.tooltipStyle)
        }, _toDisplayString(_ctx.text), 5))
      : _createCommentVNode("", true)
  ], 32))
}