<template>
    <div class="col-12 mt-6 services-offered">
        <h2 class="text-center">Avaliações dos utilizadores</h2>
        <hr>
        <div class="row">
            <div class="col-12">
                <div class="row" v-for="review in shop.reviews" v-bind:key="review.id">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 pt-3">
                                <div class="row">
                                    <div class="col-10">
                                        <h5>{{ review.user || "Utilizador anónimo" }}</h5>
                                    </div>
                                    <div class="col-2">
                                        <div class="text-center">
                                            <p>Data: {{ formatDate(review.createdAt)  }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <hr>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-10">
                                        <p>{{ review.review }}</p>
                                    </div>
                                    <div class="col-2 text-center">
                                        <i v-for="n in Math.floor(review.rating)" :key="'full-star-' + n" class="fas fa-star"></i>
                                        <i v-if="review.rating % 1 >= 0.5" :key="'half-star'" class="fas fa-star-half-alt"></i>
                                        <i v-for="n in 5 - Math.ceil(review.rating)" :key="'empty-star-' + n" class="far fa-star"></i><br>
                                        <span class="text-small">({{ review.rating }} de 5)</span>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="row" v-if="shop.reviews.length === 0">
                    <div class="col-12 text-center pt-5 pb-5">
                        <h2>Ainda sem comentários dos utilizadores.</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ShopReviews',
    props: {
        shop: {
            type: Object,
            required: true
        }
    },
    methods: {
        formatDate(date: string) {
            const d = new Date(date);
            return d.toLocaleDateString();
        }
    }
});
</script>
<style scoped>

.fas.fa-map-marker-alt, .fas.fa-phone, .fas.fa-star, .fas.fa-star-half-alt, .far.fa-star, .fas.fa-inbox {
    color: #EA5455;
}


.services-offered {
    padding: 20px;
    background-color: white !important;
    border-radius: 10px;
    margin-top: 20px;
    overflow-y: scroll;
    border: 1px solid #d7d7d7;
}

.text-center {
    text-align: center !important;
    float: auto;
    margin: auto;
}

.text-small {
    font-size: 10px;
}
</style>