<template>
    <button type="button" class="btn facebook-btn btn-block col-12" @click="click">
        <i class="fab fa-facebook-f mr-2"></i> Iniciar Sessão com Facebook
    </button>
</template>
<script lang="ts">
export default {
  name: 'FacebookSocialLogin',
  props: {
    click: {
      type: Function,
      required: true
    }
  }
}
</script>