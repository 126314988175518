<template>
    <div class="topMenuContination">
    </div>
    <div class="container searchBoxOverlap">
        <div class="row justify-content-center">
            <form @submit.prevent="updateSearch" class="col-12 searchBox">
                <div class="input-group">
                    <!-- Search Dropdown -->
                    <select class="form-select" v-model="query">
                        <option value="" selected disabled>O que procura?</option>
                        <option v-for="option in dropdownOptions" :value="option.value" v-bind:key="option.value">
                            {{ option.name }}
                        </option>
                    </select>

                    <!-- Location Input -->
                    <input type="text" class="form-control" placeholder="A sua localização?" v-model="location">

                    <!-- Search Button -->
                    <button type="submit" class="btn btn-primary">Pesquisar</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { services } from '@/interfaces/searchOptions';

export default defineComponent({
    name: 'smallSearchComponent',
    data() {
        return {
            query: '',
            location: '',
            dropdownOptions: services,
        }
    },
    props: {
        showPreviousButton: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        async updateSearch() {
            if (this.location === this.$route.query.location && this.query === this.$route.query.query) {
                return;
            }

            this.$swal.fire({
                html: '<div class="container-fluid text-center pt-5 pb-5"><div class="spinner"></div></div>',
                showConfirmButton: false,
                allowOutsideClick: false,
                backdrop: true,
            });

            await this.$router.push({
                path: '/pesquisa',
                query: {
                    query: this.query,
                    location: this.location,
                }
            });

            this.$emit('search', this.query, this.location);
        }
    },
    mounted() {
        var query = this.$route.query.query;
        var location = this.$route.query.location;

        if (!query || !location) {
            query = window.localStorage.getItem('query') || '';
            location = window.localStorage.getItem('location') || '';
        }

        this.query = query as string;
        this.location = location as string;
    }
});
</script>

<style scoped>
.topMenuContination {
    height: 30px;
    background-color: #EF4E22;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 1);
}

h3 {
    color: #EA7C69 !important;
}

.searchBoxOverlap {
    margin-top: -30px;
}

.container-fluid {
    padding: unset;
    padding-top: 50px !important;
    padding-bottom: 50px !important;
}

.btn-primary {
    padding: 14px !important;
    width: 180px !important;
}

.searchBox {
    border-radius: 10px;
    padding: 40px;
    border-bottom: unset;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

h1 {
    color: white !important;
    -webkit-text-stroke: 1px #888888;
    font-size: 48px;
    font-weight: bold;
    -webkit-text-stroke-width: 2px;
    font-family: Verdana;
}


form.searchBox {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    padding: 0;
    border: 1px solid #e5e5e5;
    overflow: hidden;
    border: 2px solid #000000;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

.input-group {
    display: flex;
    align-items: center;
    border: none;
    width: 100%;
    padding: 0;
}

.input-group-text {
    border: none;
    background-color: transparent;
    padding: 10px 15px;
    color: #333;
    font-size: 16px;
}

.form-select, 
.form-control {
    border: none;
    padding: 10px 15px;
    font-size: 16px;
    outline: none;
    box-shadow: none;
}

select.form-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    padding-right: 30px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath fill='%23333' d='M0 2l5 5 5-5H0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
}

.form-control {
    border-left: 1px solid #e5e5e5;
}

button.btn.btn-primary {
    background-color: #ff5722; /* Bold orange */
    color: white;
    border: none;
    padding: 12px 30px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 0;
    height: 100%;
}

button.btn.btn-primary:hover {
    background-color: #e64a19;
}

.row {
    margin: 0;
}

.col-8.offset-2 {
    display: flex;
    padding: 0;
    margin: 0;
    max-width: 800px;
}

.col {
    flex: 1;
    padding: 0;
}

.text-center {
    text-align: center;
}

@media (max-width: 768px) {
    .col-8.offset-2 {
        flex-direction: column;
    }

    .input-group {
        flex-direction: column;
    }

    .input-group-text, .form-select, .form-control {
        padding: 10px 15px;
        font-size: 14px;
    }

    button.btn.btn-primary {
        width: 100%;
        padding: 12px;
    }
}

.previous-btn {
    position: absolute;
    top: 80px;
    left: 20px;
    color: white !important;
    display: block;
    z-index: 4999;
    width: auto;
    height: 78px;
}

.previous-btn > div {
    padding-top: 25px;
    color: #EF4E22 !important;
    transition: ease-in-out 0.3s;
    cursor: pointer;
}

.previous-btn > div:hover {
    color: #5f1e0c !important;
}

.previous-btn > div > i {
    width: 22px;
    display: block;
    float: left;
}

.previous-btn > div > span {
    width: auto;
    display: block;
    float: left;
    margin-top: 4px;
    margin-left: 5px;
}

</style>
