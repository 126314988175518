<template>
    <div>
        <input type="email" class="form-control" id="email" aria-describedby="emailHelp" placeholder="Introduza o seu e-mail" v-model="email" />
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'EmailInput',
    data() {
        return {
            email: ''
        }
    },
    watch: {
        email() {
            this.$emit('onChange', this.email);
        }
    },
})
</script>