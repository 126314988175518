<template>
    <div class="jumbotron jumbotron-fluid">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xxl-8 col-xl-8 col-12 offset-xxl-2 offset-xl-2 pb-5">
                    <h1 class="d-none d-xl-block">Está a um passo de explorer o<br>potencial DriverWise!</h1>
                    <h2 class="d-block d-xl-none">Está a um passo de explorer o<br>potencial DriverWise!</h2>
                    <h3>Faça hoje o seu registo!</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="container login mt-5 mb-5 pb-5 pt-5">
        <div class="row">
            <div class="col-12 text-center">
                <h2 style="font-size: 24px; font-weight: bolder;"><b>Registo</b></h2>
                <h5>Crie a sua conta agora</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-xxl-4 col-xl-4 col-lg-4 col-md-6 offset-xxl-4 offset-xl-4 offset-md-2">
                <form method="post" @submit.prevent="signup">
                    <div class="form-floating mt-3">
                        <input type="text" class="form-control" id="name" placeholder="Primeiro Nome" v-model="firstName" required>
                        <label for="year">Primeiro Nome <span class="text-danger">*</span></label>
                    </div>
                    <div class="form-floating mt-3">
                        <input type="text" class="form-control" id="name" placeholder="Último Nome" v-model="lastName" required>
                        <label for="year">Último Nome <span class="text-danger">*</span></label>
                    </div>
                    <div class="form-floating mt-3">
                        <input type="email" required class="form-control" id="name" placeholder="Endereço de E-mail" v-model="email">
                        <label for="year">Endereço de E-mail <span class="text-danger">*</span></label>
                    </div>
                    <div class="form-floating mt-3">
                        <input type="password" class="form-control" id="name" placeholder="Palavra passe" v-model="password" required>
                        <label for="year">Palavra Passe <span class="text-danger">*</span></label>
                    </div>
                    <div class="form-floating mt-3">
                        <input type="password" class="form-control" id="name" placeholder="Repita a Palavra passe" v-model="repeatPassword" required>
                        <label for="year">Repita a Palavra Passe <span class="text-danger">*</span></label>
                    </div>
                    <div class="form-floating mt-3 text-center">
                        <button type="submit" class="btn btn-primary btn-lg">Registar</button>
                    </div>
                    <hr>
                    <p class="text-muted mt-2 text-center">
                        Já têm uma conta?<br>
                        <router-link to="/login">Iniciar sessão</router-link>
                    </p>
                </form>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { AuthService } from '@/services/authService';
import { UserService } from '@/services/userService';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'loginView',
  data: function() {
    return {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        repeatPassword: '',
        loggingIn: false
    }
  },
  mounted() {
    document.title = 'DriverWise - Iniciar Sessão';
  },
  methods: {
    onPasswordInputUpdated(password: string) {
      this.password = password;
    },
    onEmailInputUpdated(email: string) {
      this.email = email;
    },
    signup() {
        if (this.password !== this.repeatPassword) {
            this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'As palavras passe não coincidem!',
            });
            return;
        }

        if (this.password.trim().length < 6) {
            this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'A palavra passe deve ter no mínimo 8 caracteres!',
            });
            return;
        }

        AuthService.register(this.firstName, this.lastName, this.email, this.password).then((response: number) => {
            switch(response) {
                case 0:
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Sucesso!',
                        text: 'Conta criada com sucesso!',
                        timer: 2000,
                        showConfirmButton: false,
                        allowOutsideClick: false
                    });
                    this.$router.push('/login');
                    break;
                case 1:
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Ocorreu um erro ao criar a conta!',
                        confirmButtonColor: '#EA7C69',
                    });
                    break;
                case 2:
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'O email já está em uso!',
                        confirmButtonColor: '#EA7C69',
                    });
                    break;
            }
        })
    }
  },
});
</script>
<style scoped>


.jumbotron {
    margin-bottom: 0;
}

.jumbotron-fluid {
    padding: 0 !important;
    background-color: var(--primary-color);
    background-size: cover;
    background-position: center;
    min-height: 300px;
    margin: unset !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 1);
}

.jumbotron h1 {
    color: white;
    font-size: 4.5rem;
    font-weight: 700;
    text-align: left;
}

.jumbotron h2 {
    color: white;
    font-size: 3rem;
    font-weight: 700;
    text-align: left;
}

.jumbotron h3 {
    color: white;
    font-size: 2rem;
    font-weight: 400;
    text-align: left;
}

@media (min-width: 1200px) and (max-width: 1701px) {
    .jumbotron h1 {
        font-size: 3.5rem;
    }

    .jumbotron h2 {
        font-size: 2.5rem;
    }

    .jumbotron h3 {
        font-size: 1.5rem;
    }
    
}
</style>